import React, { useEffect } from "react";
import { Link, NavLink } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import PopularBrands from './../../components/PopularBrands';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Countdown from 'react-countdown';
import { Categories } from "../Categorys";


const Directory = () => {

  useEffect(() => {

    if (window.pageYOffset > 300) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

  }, []);

  return (
    <React.Fragment>
      <section className="section-mobil">
        <Carousel infiniteLoop autoPlay showStatus={false} showThumbs={false}>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%202.png?alt=media&token=2349c39f-ee01-4826-9a71-195a39bf5fe6" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%201.png?alt=media&token=ac447f13-3b7f-4101-809c-562418682cf7" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%203.png?alt=media&token=ed96b02e-3c09-4cfb-85bf-cc7a255655bb" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%204.png?alt=media&token=c6b0e995-5191-450c-bf99-ca25ff0f4d01" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%205.png?alt=media&token=58e1ac43-ce27-49c5-a875-fd416f7e1d4d" alt="" />
          </div>
        </Carousel>
      </section>
      <section className="section-web">
        <Carousel infiniteLoop autoPlay showStatus={false} showThumbs={false}>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%202.png?alt=media&token=2349c39f-ee01-4826-9a71-195a39bf5fe6" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%201.png?alt=media&token=ac447f13-3b7f-4101-809c-562418682cf7" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%203.png?alt=media&token=ed96b02e-3c09-4cfb-85bf-cc7a255655bb" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%204.png?alt=media&token=c6b0e995-5191-450c-bf99-ca25ff0f4d01" alt="" />
          </div>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/BannersAgosto2024%2FBanners-pagina-web-Mishop-1920-x-720%205.png?alt=media&token=58e1ac43-ce27-49c5-a875-fd416f7e1d4d" alt="" />
          </div>
        </Carousel>
      </section>

      <Categories />


      {/*  Marcas  */}
      {/* <section className="bg-faded padding-top-2x padding-bottom-5x">
        <PopularBrands />
      </section> */}

      <section className="container-fluid">
        <div className="row justify-content-center" style={{ textAlign: "center" }}>
          <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/SitioWeb_Marzo2022%2Fpagos.jpeg?alt=media&token=e2f226f2-99d8-4827-a988-5d3dc2d57cd2" />
        </div>
      </section>

      <div style={{ display: 'flex', paddingRight: '20px', justifyContent: 'center', marginTop: '20px' }}>
        <a href="https://llegamarket.com/#/repatriationform?id=1&merchantID=885" target="_blank">
          <img src="https://llegamarket.com/assets/images/repatriations/aR1.png" alt="Imagen 1" width="440" height="220" style={{ marginRight: '10px' }} />
        </a>
        <a href="https://llegamarket.com/#/payment" target="_blank">
          <img src="https://llegamarket.com/assets/images/repatriations/pR3.png" alt="Imagen 2" width="440" height="220" style={{ marginLeft: '10px' }} />
        </a>
      </div>
      <br /><br />
    </React.Fragment >
  );
};

export default Directory;
